import React from 'react';
import Main from '../components/Main';
import Footer from '../components/Footer';
import NavbarPage from '../components/NavbarPage';

const Default = () => {
  return (
    <>
      <NavbarPage />
      <Main />
      <Footer />
    </>
  );
};

export default Default;
