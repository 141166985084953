import React from 'react';
import { Helmet } from 'react-helmet';
import { Tdl, Pml, Ni_, Githubfinder, Ptl, NI } from '../../images';
import Jumbotron from '../Jumbotron';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardTitle,
  MDBCardBody,
  MDBCardText,
} from 'mdbreact';

import './Home.scss';
import Folio from '../Folio';

const Home = () => {
  return (
    <>
      <Helmet>
        <link rel='canonical' href='https://clementnzau.com/home' />
      </Helmet>
      <ul className='bg-slideshow'>
        <li>
          <span></span>
        </li>
        <li>
          <span></span>
        </li>
        <li>
          <span></span>
        </li>
        <li>
          <span></span>
        </li>
      </ul>

      <Jumbotron />

      <MDBContainer className='bg-dark-1' fluid>
        {/* <MDBContainer className='pt-5'>
        <h1 className='text-center' id='feeds'>
          Feeds
        </h1>
        <MDBRow className='p-2'>
          <MDBCol md='6' className='p-2' style={{}}>
            <MDBCard className='text-white p-2 bg-1'>
              <MDBCardTitle>Latest Blog</MDBCardTitle>
              <MDBCardBody>
                <MDBCardText className='text-white'>
                  I will be publishing on this platform soon. Frequently visit
                  this site for more.
                </MDBCardText>
                <MDBCardText className='text-white'>
                  Meanwhile, check out my previous blogs from{' '}
                  <u>
                    <a
                      href='https://medium.com/@cnzau'
                      target='_BLANK'
                      rel='noopener noreferrer'
                      className='text-white'
                    >
                      medium
                    </a>
                  </u>
                  .
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol md='6' className='p-2'>
            <MDBCard className=' text-white p-2 bg-1'>
              <MDBCardTitle>Twitter</MDBCardTitle>
              <MDBCardBody>
                <MDBCardText className='text-white'>
                  Checkout my latest tweets or re-tweets.
                </MDBCardText>
                <MDBCardText className='text-white'>
                  <small>
                    FYI: I am more active on twitter than on facebook
                    <span aria-label='wink' role='img'>
                      &#x1F609;
                    </span>
                    .
                  </small>
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
       */}
        <MDBContainer id='folios' className='pt-5 pb-2 folios'>
          <h3 className='mt-2 pb-2'>Portfolio</h3>

          <Folio
            shot={Tdl}
            url='https://cnzau.github.io/tdl'
            title='Tech Plus Daily'
            tag='Website'
            details={`A client's website that I built using HTML5, CSS, Javascript, ZURB Foundation,
                jQuery, FontAwesome, GSAP, PHP mail function among other libraries.`}
          />

          <Folio
            shot={Pml}
            url='https://pmchomelogistics.com/'
            title='PMchome Logistics Ltd'
            tag='Logo, Website, Hosting, GSuite'
            details={`A website that I built using React, PHP mail function among other libraries. I also manage and maintain the client's domain, hosting and G Suite account. Lastly , I designed the logo using Adobe Illustrator.`}
          />

          <Folio
            shot={NI}
            url='http://neuro-innova.com/'
            title='Neuro Innova Limited'
            tag='Website'
            details={`A website that I contributed to. Built on MS ASP.Net web framework and hosted on Azure. Other technologies used includes Google analytics, reCAPTCHA, Bootstrap, Font awesome, and jQuery.`}
          />

          <Folio
            shot={Ni_}
            url=''
            title='Neu-Invest'
            tag='React Web App'
            details={`A web app that I built using React, Firestore and consuming a dot net RESTful api. Used highchart, kendo ui among other libraries.`}
          />

          <Folio
            shot={Githubfinder}
            url='https://githubfinder254.netlify.com/'
            title='Github Finder'
            tag='React Web App'
            details={`A react app consuming the github REST Api to find github users and give more details available from his profile.`}
          />

          <Folio
            shot={Ptl}
            url='http://cnzau.github.io/PawaTeleLtd/'
            title='Pawa Tele Limited'
            tag='Website, Logo'
            details={`One of my first websites to be publicly published in 2015 for a company that I co-founded.`}
          />
        </MDBContainer>
      </MDBContainer>
    </>
  );
};

export default Home;
