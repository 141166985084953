import React from 'react';
import { MDBContainer } from 'mdbreact';
import { Portrait } from '../images';

const Jumbotron = () => {
  const CardStyle2 = { backgroundColor: 'rgba(255,255,255,0.8)' };
  return (
    <MDBContainer
      className='align-items-center'
      id='land'
      size='sm'
      style={{
        height: '100vh',
        position: 'relative',
      }}
      fluid
    >
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <div className='text-center'>
          <img
            src={Portrait}
            className='mb-3 text-center'
            alt=''
            height='120px'
            style={{
              borderRadius: '50%',
              border: '1px doted #fff',
              ...CardStyle2,
            }}
          />
        </div>
        <h3 className='head_line my-3'>I build amazing experiences</h3>
        <ul className='txt-slideshow'>
          <li>
            <div>
              <p>Software Engineer</p>
            </div>
          </li>
          <li>
            <div>
              <p>Front-End Engineer</p>
            </div>
          </li>
          <li>
            <div>
              <p>Cloud Engineer</p>
            </div>
          </li>
          <li>
            <div>
              <p>Tech Enthusiast</p>
            </div>
          </li>
        </ul>
      </div>

      <a href='#folios' className='scroll-down' aria-label='Scroll down'>
        <div className='mouse'>
          <span></span>
        </div>
        <div className='arrow'>
          <span></span>
        </div>
      </a>
      {/* </div> */}
    </MDBContainer>
  );
};

export default Jumbotron;
