import React from 'react';
import { Helmet } from 'react-helmet';
import {
  Portrait,
  Web,
  Db,
  Cloud,
  ReactLogo,
  Html,
  Css,
  Sass,
  Js,
  Express,
  Nodejs,
  DotNet,
  CSharp,
  Nextjs,
  MongoDb,
  MySql,
} from '../../images';
import {
  MDBContainer,
  MDBCard,
  MDBCardTitle,
  MDBCardBody,
  MDBLink,
  MDBRow,
  MDBCol,
} from 'mdbreact';

import './About.scss';

const About = () => {
  return (
    <>
      <Helmet>
        <link rel='canonical' href='https://clementnzau.com/about' />
      </Helmet>
      <MDBContainer className='mt-5 pt-5'>
        <div className='p-rel'>
          <h3>About Me</h3>

          <div className='about-intro'>
            <p className=''>
              Hello! I am Clement, a software engineer based in Nairobi, Kenya.
            </p>
            <p>
              I enjoy creating things that live on the internet, whether that be
              websites, applications, databases, cloud based systems just to
              mention a few in relation to I.T. My goal is to always build
              products that provide amaizing experiences.
            </p>
            {/* <p>
              3+ years of professional experience in web application
              development, UI/UX and recently in cloud.{' '}
            </p> */}
            <p className='small'>Always up for learning new things.</p>
          </div>
          <div className='portrait'>
            <img src={Portrait} alt='' />
            <div className='overlay-effect'></div>
          </div>
        </div>
      </MDBContainer>
      <MDBContainer className='mt-2 mb-3 pt-5 pb-3'>
        <h3>Tech Stack</h3>
        <MDBRow between className='my-stack'>
          <MDBCol>
            <img src={Web} height='30px' width='30px' alt='' />
            <span>Web App Development</span>
          </MDBCol>

          <MDBCol>
            <img src={Db} height='30px' width='30px' alt='' />
            <span>Database Management</span>
          </MDBCol>
          <MDBCol>
            <img src={Cloud} height='30px' width='30px' alt='' />
            <span>Cloud Architecture</span>
          </MDBCol>
          <div className='w-100' />
          <MDBCol>
            <img src={ReactLogo} height='30px' width='30px' alt='' />
            <span>ReactJs</span>
          </MDBCol>
          <MDBCol>
            <img src={Nextjs} height='30px' width='30px' alt='' />
            <span>NextJs</span>
          </MDBCol>
          <MDBCol>
            <img src={Express} height='30px' width='30px' alt='' />
            <span>Express</span>
          </MDBCol>
          <MDBCol>
            <img src={Nodejs} height='30px' width='30px' alt='' />
            <span>NodeJs</span>
          </MDBCol>
          <MDBCol>
            <img src={Js} height='30px' width='30px' alt='' />
            <span>JavaScript</span>
          </MDBCol>
          <div className='w-100' />

          <MDBCol>
            <img src={DotNet} height='30px' width='30px' alt='' />
            <span>Asp.Net</span>
          </MDBCol>
          <MDBCol>
            <img src={CSharp} height='30px' width='30px' alt='' />
            <span>C#</span>
          </MDBCol>
          <MDBCol>
            <img src={Html} height='30px' width='30px' alt='' />
            <span>HTML</span>
          </MDBCol>

          <MDBCol>
            <img src={Css} height='30px' width='30px' alt='' />
            <span>Css</span>
          </MDBCol>
          <MDBCol>
            <img src={Sass} height='30px' width='30px' alt='' />
            <span>Sass</span>
          </MDBCol>
          <MDBCol>
            <img src={MongoDb} height='30px' width='30px' alt='' />
            <span>Mongo Db</span>
          </MDBCol>
          <MDBCol>
            <img src={MySql} height='30px' width='30px' alt='' />
            <span>My SQL</span>
          </MDBCol>
          <div className='w-100' />
        </MDBRow>
      </MDBContainer>
    </>
  );
};

export default About;
