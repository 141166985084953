import React, { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import FloatButton from './FloatButton';

import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBCollapse,
  MDBHamburgerToggler,
} from 'mdbreact';

const NavbarPage = () => {
  const [state, setState] = useState({
    collapse: false,
    collapseID: '',
  });

  const toggleSingleCollapse = (collapseId) => {
    setState({
      ...state,
      [collapseId]: !state[collapseId],
    });
  };

  return (
    <header>
      <MDBNavbar
        dark
        color={state.collapse ? 'elegant-color' : ''}
        expand='md'
        className='z-depth-0'
        scrolling
        fixed='top'
      >
        <MDBNavbarBrand href='/'>
          <div className='brand bg-1 z-depth-1 hoverable'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='30'
              height='30'
              viewBox='0 0 30 30'
              fill='none'
            >
              <g clip-path='url(#clip0)'>
                <path
                  d='M25.209 26.0035C23.065 27.9887 20.3877 29.305 17.5057 29.791C14.6237 30.2769 11.6624 29.9113 8.98538 28.739C6.30835 27.5667 4.03204 25.6388 2.43587 23.1919C0.839697 20.7449 -0.0068846 17.8855 4.21683e-05 14.9644C0.00696893 12.0434 0.867103 9.18802 2.47486 6.74867C4.08262 4.30931 6.36805 2.39218 9.05061 1.2326C11.7332 0.0730159 14.6961 -0.27856 17.5758 0.221025C20.4555 0.720611 23.1265 2.04962 25.2611 4.04492C25.2611 4.04492 25.7149 3.2 23.523 5.90221C21.331 8.60442 19.532 3.1415 17.1406 2.72661C14.7491 2.31172 12.2885 2.60369 10.0607 3.56668C7.83298 4.52967 5.93502 6.12178 4.59983 8.14757C3.26465 10.1734 2.55034 12.5447 2.54458 14.9705C2.53883 17.3963 3.24189 19.7709 4.56745 21.803C5.89301 23.8351 7.7834 25.4362 10.0066 26.4097C12.2298 27.3833 14.689 27.6869 17.0824 27.2833C19.4758 26.8798 21.6992 25.7866 23.4797 24.138L25.209 26.0035Z'
                  fill='white'
                />
                <path d='M9 8.60004L23.35 24H9V8.60004Z' fill='#00FFFF' />
                <path
                  d='M24 24L21 20.85L21 9H24L24 16.4507L24 24Z'
                  fill='#00FFFF'
                />
                <line x1='27' y1='15' x2='22' y2='15' stroke='white' />
                <path d='M26 17.5H22.5V21.5' stroke='white' />
                <circle cx='28' cy='15' r='1.5' fill='white' />
                <circle cx='25.75' cy='17.5' r='1.5' fill='white' />
                <rect x='24' y='22.5' width='4' height='1.5' fill='white' />
              </g>
              <defs>
                <clipPath id='clip0'>
                  <rect width='30' height='30' fill='white' />
                </clipPath>
              </defs>
            </svg>
            <span>
              clement <br />
              nzau T.
              {/* <br />
              timothy */}
            </span>
          </div>
        </MDBNavbarBrand>
        <MDBHamburgerToggler
          id='hamburger1'
          onClick={() => toggleSingleCollapse('collapse')}
        />
        <MDBCollapse isOpen={state.collapse} navbar>
          <MDBNavbarNav right>
            <MDBNavItem>
              <MDBNavLink
                to='/home'
                onClick={
                  state.collapse ? () => toggleSingleCollapse('collapse') : null
                }
              >
                Home
              </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
              <MDBNavLink
                to='/about'
                onClick={
                  state.collapse ? () => toggleSingleCollapse('collapse') : null
                }
              >
                About
              </MDBNavLink>
            </MDBNavItem>
            {/* <MDBNavItem>
              <MDBNavLink
                to='/work'
                onClick={
                  state.collapse ? () => toggleSingleCollapse('collapse') : null
                }
              >
                Work
              </MDBNavLink>
            </MDBNavItem> */}
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBNavbar>
      {!useRouteMatch('/contact') && <FloatButton />}
    </header>
  );
};

export default NavbarPage;
