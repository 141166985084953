import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactGA from 'react-ga';
import Default from './layouts/Default';
import './App.scss';

ReactGA.initialize('UA-78291631-2');

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
      <div className='App'>
        <Router>
          <Default />
        </Router>
      </div>
    </>
  );
}

export default App;
