import React from 'react';

function Folio(props) {
  const { shot, url, title, tag, details } = props;
  return (
    <div className='folio'>
      <div className='f_shot'>
        <img src={shot} alt='' />
        <div className='f_overlay'>
          {url && (
            <a href={url} target='_BLANK' rel='noopener noreferrer'>
              <span role='img' aria-label='Link'>
                &#x1F517;
              </span>
            </a>
          )}
        </div>
      </div>
      <div className='f_desc'>
        <h4 className='f_title'>{title}</h4>
        <div className='f_tag'>{tag}</div>
        <div className='f_details'>{details}</div>
        <div className='f_link'>
          {url && (
            <a
              href={url}
              aria-label='View portfolio'
              target='_BLANK'
              rel='noopener noreferrer'
            >
              <span role='img' aria-label='Link'>
                &#x1F441;
              </span>
            </a>
          )}
        </div>
      </div>
    </div>
  );
}

export default Folio;
